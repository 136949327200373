require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.includes");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/loading/style", "vant/es/loading", "vant/es/icon/style", "vant/es/icon"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/loading/style"), require("vant/es/loading"), require("vant/es/icon/style"), require("vant/es/icon"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.loading, global.style, global.icon);
    global.checkInfo = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _loading, _style3, _icon) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _loading = _interopRequireDefault(_loading);
  _icon = _interopRequireDefault(_icon);
  var _default = {
    name: "CheckInfo",
    components: {
      Icon: _icon.default,
      Loading: _loading.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost; // 是否需要切换至新版质检逻辑

      return {
        ossHost: ossHost,
        signalPass: false,
        motorPass: false,
        uniqueId: "-",
        signal: "-",
        warningList: [// {text: '数据有效', pass: true},
        // {text: '设备角度正常，正常泵气', pass: true},
        {
          text: '正常读取探头数据',
          pass: false
        } // {text: '探头稳定', pass: true},
        // {text: '算法在规定时间内给出目标值', pass: true}
        ],
        errorStatus: true,
        imgUrl: 'img/goods-ryy.png',
        sensorPass: false,
        sensorErrText: '',
        deviceName: '',
        adErrText: '',
        // 气泵状态：0是开启，1是关闭
        pumpStatus: 0,
        updatePumpStatusLoading: false,
        // 气路自检状态：0未开始自检，1点击下发自检命令，2下发成功等待自检结果
        pumpCheckStatus: 0,
        pumpPass: false,
        pumpCheckErrorText: '',
        // 获取质检状态
        loadingData: false,
        // 气路自检loading状态
        pumpLoad: true,
        // 是否不支持气路检测
        notPumpCheck: false,
        themeColor: '#209A56',
        // 溶氧类型
        // 3,4 电解法溶氧仪、5-16 荧光法溶氧仪，17是小方帽
        ryType: 14,
        // 小方帽饱和氧合格状态
        fullOxyPass: false,
        // 小方帽零氧合格状态
        zeroOxyPass: false
      };
    },
    created: function created() {
      var vm = this;
      vm.fullCount = 0;
      vm.$route.query.uuid ? vm.loadDeviceInfo() : vm.$router.replace({
        path: "/factory/index"
      });
    },
    destroyed: function destroyed() {
      // 清除定时器
      this.loadCheckIntervalId && clearInterval(this.loadCheckIntervalId);
      this.intervalId && clearInterval(this.intervalId);
    },
    methods: {
      passAction: function passAction() {
        var vm = this;

        if (vm.ryType == 17) {
          if (!(vm.signalPass && vm.fullOxyPass && vm.zeroOxyPass)) {
            return;
          }
        } else {
          if (!(vm.signalPass && vm.motorPass && !vm.errorStatus && vm.sensorPass)) {
            return;
          }

          if (!vm.notPumpCheck && !vm.pumpPass) {
            return;
          }
        }

        var USER_NAME = vm.$config.keys.USER_NAME;
        var name = vm.$localStorage.getItem(USER_NAME);

        var tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });

        vm.$http({
          method: "post",
          except: true,
          url: "".concat(vm.$config.base.BASE_URL, "admin/iotTool/product/device/").concat(vm.uniqueId, "/pass"),
          data: {
            name: name,
            signal: vm.signal > 100 ? vm.signal - 100 : vm.signal,
            v: 2
          }
        }).then(function (res) {
          var code = res.code,
              data = res.data,
              message = res.message;
          tt.clear();

          if (code == 1) {
            _toast.default.success({
              message: data || "已成功通过质检~",
              forbidClick: true,
              overlay: true,
              duration: 3000,
              onClose: function onClose() {
                vm.$router.replace({
                  path: "/factory/index"
                });
              }
            });
          } else {
            _toast.default.fail({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 3000
            });
          }
        }).catch(function (err) {
          console.log(err);
          tt.clear();

          _toast.default.fail({
            message: JSON.stringify(err),
            forbidClick: true,
            overlay: true,
            duration: 3000
          });
        });
      },
      // 打开溶氧仪气泵
      onclickUpdatePumpBtn: function onclickUpdatePumpBtn() {
        var vm = this;
        var _vm$$config$base = vm.$config.base,
            ADMIN_URL = _vm$$config$base.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base.ADMIN_TOKEN;
        vm.updatePumpStatusLoading = true;
        vm.$http({
          type: "PUT",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/devices/register/config/").concat(vm.deviceId),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true,
          data: {
            stopPump: 0
          }
        }).then(function (res) {
          var code = res.code,
              message = res.message;
          vm.updatePumpStatusLoading = false;

          if (code == 1) {
            vm.pumpStatus = 0;

            _toast.default.success({
              message: '已成功开启气泵',
              forbidClick: true,
              overlay: true,
              duration: 3000
            });
          } else {
            _toast.default.fail({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 3000
            });

            vm.loadDeviceConfig(vm.deviceId);
          }
        }).catch(function (err) {
          console.log(err);
        });
      },
      // 重新检测
      onClickShowStartCheckModalBtn: function onClickShowStartCheckModalBtn() {
        var vm = this;
        var _vm$$config$base2 = vm.$config.base,
            ADMIN_URL = _vm$$config$base2.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base2.ADMIN_TOKEN;
        vm.pumpLoad = true;
        vm.pumpCheckErrorText = '';
        vm.$http({
          type: "put",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/ry/").concat(vm.ryDeviceId, "/originCommand"),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true,
          data: {
            airChanelCheckRequest: {
              motorHz: 0,
              motorRatio: 0
            },
            status: 1
          }
        }).then(function (res) {
          var code = res.code,
              message = res.message; // 自检指令下发成功

          if (code == 1) {
            vm.startTime = new Date().getTime();
            vm.loadCheckIntervalId = setInterval(vm.loadRyCheckStatus, 3000);
          } else {
            _toast.default.fail({
              message: message || '自检指令下发超时，请重试',
              forbidClick: true,
              overlay: true,
              duration: 4000
            });

            vm.pumpLoad = false;
          }
        });
      },
      // 获取气路自检结果列表
      loadRyCheckStatus: function loadRyCheckStatus() {
        var vm = this;
        var _vm$$config$base3 = vm.$config.base,
            ADMIN_URL = _vm$$config$base3.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base3.ADMIN_TOKEN;
        vm.$http({
          url: "".concat(ADMIN_URL, "javaApi/iot/ry/").concat(vm.ryDeviceId, "/airChannelCheckRecords"),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          var code = res.code,
              data = res.data,
              message = res.message;

          if (code == 1 && data.length) {
            // 重新排序
            var listArr = data.sort(function (a, b) {
              return b.createAt - a.createAt;
            }); // 有有效结果更改页面显示状态

            if (Math.abs(new Date(listArr[0].createAt).getTime() - vm.startTime) < 3600 * 1000 * 2) {
              var _listArr$ = listArr[0],
                  inWater = _listArr$.inWater,
                  inletValveWarn = _listArr$.inletValveWarn,
                  outValveWarn = _listArr$.outValveWarn,
                  pumpWarn = _listArr$.pumpWarn;
              clearInterval(vm.loadCheckIntervalId);
              vm.ttid && vm.ttid.clear();
              vm.pumpPass = inWater != 1 && !inletValveWarn && !outValveWarn && !pumpWarn;

              if (!vm.pumpPass) {
                var errArr = [];
                inWater == 1 && errArr.push('进水');
                inletValveWarn && errArr.push('充气阀异常');
                outValveWarn && errArr.push('排气阀异常');
                pumpWarn && errArr.push('气泵异常');
                errArr.length && (vm.pumpCheckErrorText = errArr.join(','));
              }

              vm.pumpLoad = false;
            } else {
              // 三分钟还未获取到数据算超时
              console.log(new Date().getTime() - vm.startTime);

              if (new Date().getTime() - vm.startTime > 1000 * 180 || !vm.loadCheckIntervalId) {
                clearInterval(vm.loadCheckIntervalId);
                vm.ttid && vm.ttid.clear();
                vm.loadCheckIntervalId && _toast.default.fail({
                  message: message || '自检指令下发超时，请重试',
                  forbidClick: true,
                  overlay: true,
                  duration: 4000
                });
                vm.pumpLoad = false;
              }
            }
          } else {
            // 三分钟还未获取到数据算超时
            if (new Date().getTime() - vm.startTime > 1000 * 180 || !vm.loadCheckIntervalId) {
              clearInterval(vm.loadCheckIntervalId);
              vm.ttid && vm.ttid.clear();
              vm.loadCheckIntervalId && _toast.default.fail({
                message: message || '自检指令下发超时，请重试',
                forbidClick: true,
                overlay: true,
                duration: 4000
              });
              vm.pumpLoad = false;
            }
          }
        }).catch(function (err) {
          vm.ttid && vm.ttid.clear();
          vm.pumpLoad = false;

          _toast.default.fail({
            message: err.message || '自检指令下发超时，请重试',
            forbidClick: true,
            overlay: true,
            duration: 4000
          });
        });
      },
      // 获取设备配置
      loadDeviceConfig: function loadDeviceConfig(deviceId) {
        var vm = this;
        var _vm$$config$base4 = vm.$config.base,
            ADMIN_URL = _vm$$config$base4.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base4.ADMIN_TOKEN;
        vm.$http({
          type: "get",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/devices/register/config/").concat(deviceId),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          var code = res.code,
              data = res.data,
              message = res.message;
          vm.ttid && vm.ttid.clear();
          vm.ttid = "";

          if (code == 1) {
            vm.ryDeviceId = data.deviceId;

            if (data && data.configFields) {
              vm.pumpStatus = data.configFields[22].value;
            } // 如果新版质检流程刚进来的时候获取该设备的质检结果


            vm.startTime = new Date().getTime();
            vm.loadRyCheckStatus();
          } else {
            _toast.default.loading({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 3000
            });
          }
        });
      },
      loadAdLine: function loadAdLine(deviceId) {
        var vm = this;
        var _vm$$config$base5 = vm.$config.base,
            ADMIN_URL = _vm$$config$base5.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base5.ADMIN_TOKEN,
            oxygenValList = _vm$$config$base5.oxygenValList;
        var errObj = {
          "ERR_NONE": "正常",
          "ERR_PIPE_LEAKAGE": "管道漏气",
          "ERR_NOT_CLASSIFIED": "未分类",
          "ERR_SENSOR_BROKEN": "探头严重破损",
          "ERR_SENSOR_POLLUTED": "探头污染",
          "ERR_MINOR_LEAKAGE": "探头轻微破损",
          "ERR_UNKNOWN_ERROR": "未知类型异常",
          "ERR_FLAT_IN_AIR": "探头未接触水面",
          "ERR_FLAT_IN_WATER": "探头泡水"
        }; // const year = new Date().getUTCFullYear();
        // const month = new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`;
        // const days = new Date().getDate() > 9 ? new Date().getDate() : `0${new Date().getDate()}`;

        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1 > 9 ? new Date().getMonth() + 1 : "0".concat(new Date().getMonth() + 1);
        var date = new Date().getDate() > 9 ? new Date().getDate() : "0".concat(new Date().getDate());
        vm.$http({
          type: "get",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/ry/").concat(deviceId, "/adSampleList/").concat(year).concat(month).concat(date),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          var code = res.code,
              data = res.data,
              message = res.message;
          vm.ttid && vm.ttid.clear();
          vm.ttid = "";

          if (code == 1) {
            // data = [{"_id":"62a6fd1fa0b7024203d541ef","device_id":"1104589390","data_index":1,"oxygen":0.0,"last_oxygen":7.4,"voltage":0.1,"last_voltage":74.6,"temperature":0,"last_temperature":295,"start_time":1010,"test_time":72,"spacing_time":5,"type":2,"data_count":132,"data_group":"[748, 748, 731, 714, 617, 523, 436, 356, 319, 283, 219, 163, 86, 50, 15, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 9, 70, 146, 172, 199, 258, 325, 401, 443, 485, 577, 677, 699, 705, 711, 719, 725, 731, 734, 735, 737, 740, 741, 743, 743, 743, 744, 745, 745, 745, 745, 745, 745, 746, 746, 746, 747, 746, 746, 746, 746, 747, 747, 747, 747, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746, 746]","create_time":"2022-06-13 17:02:23","errorCode":"ERR_NONE","sensorId":"22060384","device_error":"ERR_NONE","measured_o2":0.0,"measured_ad":1,"final_o2":0.0,"saturated_AD":749,"zero_AD":0,"calib_temperature":29.0,"sensor_broken":0,"sensor_broken_ad":0,"calibType":12}]
            if (data && data.length) {
              var sensorErrText = '';
              var arr = data.sort(function (a, b) {
                return new Date(b.create_time.split(' ').join('T')).getTime() - new Date(a.create_time.split(' ').join('T')).getTime();
              });
              var adList = JSON.parse(arr[0].data_group) || [];
              var fullOxy = 0;
              var tmp = parseInt((arr[0].temperature || arr[0].last_temperature) / 10); // 零氧点取6分钟的点，低于0.2

              var zeroOxy = adList[72];

              if (arr[0] && new Date().getTime() - new Date(arr[0].create_time.split(' ').join('T')).getTime() < 3000 * 1000) {
                if (arr[0] && ['ERR_NONE', 'ERR_SENSOR_POLLUTED'].includes(arr[0].errorCode)) {
                  vm.motorPass = true;
                } else {
                  arr[0] && (vm.adErrText = errObj[arr[0].errorCode]);
                } // 饱和氧用当前AD曲线前后点的最大值


                if (adList[0] || adList[adList.length - 1]) {
                  fullOxy = adList[adList.length - 1] > adList[0] ? adList[adList.length - 1] : adList[0];
                } // 探头饱和氧偏差值


                var fullOxyVg = (fullOxy / 100 - oxygenValList[tmp]) / oxygenValList[tmp]; // 饱和氧取点，第一个、最后一个或者后台给的满足条件就行

                vm.sensorPass = fullOxyVg <= 0.1 && fullOxyVg > -0.3 && zeroOxy <= 20;
                console.log(fullOxyVg);
                console.log(zeroOxy);

                if (!vm.sensorPass) {
                  !(fullOxyVg <= 0.1 && fullOxyVg > -0.3) && (sensorErrText = '饱和氧偏差过大');
                  zeroOxy > 50 && (sensorErrText = sensorErrText ? '饱和氧跟收敛时间都不合格' : '收敛时间不合格');
                  vm.sensorErrText = sensorErrText;
                }
              }
            }
          } else {
            _toast.default.loading({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 3000
            });
          }
        }).catch(function (err) {
          console.log(err);
          vm.ttid && vm.ttid.clear();
          vm.ttid = "";
        });
      },
      loadDeviceInfo: function loadDeviceInfo() {
        var vm = this;
        vm.loadingData = true; // vm.ttid = Toast.loading({
        //     message: "加载中...",
        //     forbidClick: true,
        //     overlay: true,
        //     duration: 0
        // });

        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.BASE_URL, "admin/iotTool/product/device/").concat(vm.$route.query.uuid),
          except: true
        }).then(function (res) {
          var code = res.code,
              data = res.data,
              message = res.message;

          if (code == 1) {
            if (!data.uniqueId) {
              _toast.default.fail({
                message: "二维码错误，设备不存在~",
                forbidClick: true,
                overlay: true,
                duration: 3000,
                onClose: function onClose() {
                  vm.$router.replace({
                    path: "/factory/index"
                  });
                }
              });
            }

            vm.uniqueId = data.uniqueId;
            vm.deviceId = data.deviceId;
            vm.signal = data.list[0] ? data.list[0].signal : 0;
            vm.signal = vm.signal > 100 ? vm.signal - 100 : vm.signal;
            vm.signalPass = vm.signal > 100 ? vm.signal >= 119 : vm.signal >= 19;
            vm.deviceName = vm.$strTool.filterRyyDeviceName(data.type);
            vm.ryType = data.type; // 小方帽出厂测试流程
            // 只测饱和氧偏差+零氧<0.3+信号

            if (data.type == 17) {
              vm.imgUrl = 'img/ry-xfm.png';
              vm.loadXfmOxyData();
              !vm.intervalId && (vm.intervalId = setInterval(vm.loadXfmOxyData, 10000));
            } else {
              // 小红帽出厂测量流程
              vm.loadAdLine(data.deviceId);
              vm.loadDeviceConfig(data.deviceId);
              data.list.sort(function (a, b) {
                return new Date(b.createTime).getTime() - new Date(a.createTime).getTime();
              });

              if (data.type > 4) {
                vm.imgUrl = 'img/goods-ygf-ryy.png';
              }

              data.list.length && vm.checkErrorCode(data.list[0].errorCode); // 关闭持续测量模式

              vm.closeKeepMeasure(vm.uniqueId);
            }
          } else {
            vm.ttid && vm.ttid.clear();
            vm.ttid = "";

            _toast.default.loading({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 3000
            });
          }

          vm.loadingData = false;
        }).catch(function (err) {
          console.log(err);
          vm.ttid && vm.ttid.clear();
          vm.ttid = "";
          vm.loadingData = false;
        });
      },
      // 关闭溶氧持续测量模式
      closeKeepMeasure: function closeKeepMeasure(uniqueId) {
        var vm = this;
        var _vm$$config$base6 = vm.$config.base,
            ADMIN_URL = _vm$$config$base6.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base6.ADMIN_TOKEN; // 0关闭持续测量 1开启

        var status = 0;
        vm.$http({
          type: "get",
          url: "".concat(ADMIN_URL, "javaApi/iot/ry/").concat(uniqueId, "/keepMeasure/").concat(status),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then();
      },
      // code： 0表示正常，1表示有异常
      checkErrorCode: function checkErrorCode(code) {
        var vm = this; // const codeStr = vm.$strTool.dec2bin(code, 8);
        // `${codeStr}`.split('').map((c, index) => {
        //     if (index == 2) {
        //         c == '1' && (vm.errorStatus = true)
        //         c == '1' && (vm.warningList[0].pass = false);
        //     }
        // })

        if (Number(code) == 0) {
          vm.errorStatus = false;
          vm.warningList[0].pass = true;
        }
      },
      refreshSignal: function refreshSignal() {
        var vm = this;
        vm.loadDeviceInfo();
      },
      // 主动刷新小方帽数据
      loadXfmOxyData: function loadXfmOxyData() {
        var vm = this;
        var _vm$$config$base7 = vm.$config.base,
            ADMIN_URL = _vm$$config$base7.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base7.ADMIN_TOKEN;
        vm.$http({
          type: "get",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/refreshStatus/").concat(vm.deviceId),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          console.log(res);
          vm.loadXfmUpdateLog();
        }).catch(function (err) {
          console.log(err);
        });
      },
      // 获取小方帽上报的日志
      loadXfmUpdateLog: function loadXfmUpdateLog() {
        var vm = this;
        var _vm$$config$base8 = vm.$config.base,
            ADMIN_URL = _vm$$config$base8.ADMIN_URL,
            ADMIN_TOKEN = _vm$$config$base8.ADMIN_TOKEN,
            oxygenValList = _vm$$config$base8.oxygenValList;
        vm.$http({
          type: "get",
          // url: `${ADMIN_URL}javaApi/iot/factoryRyAdSample/${deviceId}`,
          url: "".concat(ADMIN_URL, "javaApi/iot/logs/").concat(vm.deviceId, "/v2?start=").concat(new Date().getTime() - 1200000, "&end=").concat(new Date().getTime(), "&pageNo=1&type=2"),
          headers: {
            'access-token': ADMIN_TOKEN
          },
          except: true
        }).then(function (res) {
          res.data.length && res.data.map(function (item) {
            // 上报数据check
            if (item.log.includes('数据索引:')) {
              var tmp = parseInt(Number(item.log.split('测量温度')[1].split(',')[0]));
              var fullOxy = Number(item.log.split('水中电压')[1].split(',')[0]);
              var signal = Number(item.log.split('信号强度')[1].split(',')[0]);
              var readSucc = item.log.includes('正常读取'); // 正常读取数据的情况下判断饱和氧+零氧

              if (readSucc) {
                // 探头饱和氧偏差值，至少5个溶氧点才能满足
                var fullOxyVg = (fullOxy / 100 - oxygenValList[tmp]) / oxygenValList[parseInt(tmp)];

                if (fullOxyVg <= 0.1 && fullOxyVg > -0.3) {
                  vm.fullCount++;

                  if (vm.fullCount >= 3) {
                    vm.fullOxyPass = true;
                  }
                }

                vm.signal = signal;
                vm.signalPass = signal - 100 >= 18;

                if (fullOxy <= 20 && fullOxy) {
                  vm.zeroOxyPass = true;
                }
              }
            }
          });

          if (vm.fullOxyPass && vm.zeroOxyPass) {
            vm.loadingData = false;
            clearInterval(vm.intervalId);
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  };
  _exports.default = _default;
});